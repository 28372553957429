<template>
  <div class="donation-select-collection-container">
    <!-- 搜索样式 -->
    <div v-if="isSearch">
      <div
        class="donation-select-collection-list"
        v-if="collectionSelectList.length"
      >
        <div
          class="donation-select-collection-item"
          v-for="(item,index) in collectionSelectList"
          :key="index"
          @click="selectDonationCollection(item)"
        >
          <div
            style="height: 100%;"
            class="row-between"
          >
            <div class="row-start">
              <div :style="'width: 5rem;height: 5rem;margin: 0 0.875rem 0 0;background-image: url('+ item.image +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0%;background-size: 100% auto;background-color: transparent;border-radius: 4px;'"></div>
              <!-- <img
              class="donation-select-collection-item-img"
              :src="item.image"
            /> -->
              <div>
                <p
                  style="width:60vw;"
                  class="donation-select-collection-item-title"
                >{{ item.name }}</p>
                <p
                  style="width:60vw;"
                  class="donation-select-collection-item-id"
                >{{ item.showId && item.showId != '0' ? item.showId :item.uniqueCode }}</p>
                <img
                  class="select-collection-type"
                  :src="mediaType[item.cateId] == 'img' ? require('../assets/icon/media/img.png') : mediaType[item.cateId] == 'audio' ?  require('../assets/icon/media/audio.png'): mediaType[item.cateId] == 'video' ?  require('../assets/icon/media/video.png') :  require('../assets/icon/media/3d.png')"
                  alt=""
                >
              </div>
            </div>
            <img
              class="donation-select-collection-item-selectimg"
              v-if="selectUniqueListNotConfirm.filter(i => i == item.uniqueCode).length"
              src="../assets/icon/select-collection.png"
            />
            <img
              class="donation-select-collection-item-selectimg"
              v-else
              src="../assets/icon/no-select-collection.png"
            />
          </div>
        </div>

      </div>
    </div>
    <!-- 初始选择样式 -->
    <div
      class="row-start"
      v-if="!isSearch && collectionSelectList.length"
    >
      <div
        class="donation-collection-tip"
        :class="isSafari ? 'donation-collection-tip-safari' : 'donation-collection-tip-not-safari'"
      >
        <div
          v-for="(i,j) in collectionInfo.skuVos"
          :key="j"
          @click="selectDonationCollectionIndex(j)"
          class="donation-collection-tip-item row-center"
          :style="selectCollectionIndex == j ? 'background: #2C2C2C;' : ''"
        >
          <div :style="'position: relative;width: 5rem;height: 5rem;background-image: url('+ i.image +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0%;background-size: 100% auto;background-color: transparent;border-radius: 4px;'">
            <img
              v-if="i.selectSize != 0"
              class="donation-collection-num-image"
              src="../assets/icon/collection-num.png"
            />
            <div
              v-if="i.selectSize != 0"
              class="donation-collection-num"
            >
              {{ i.selectSize }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="donation-collection-content"
        :class="isSafari ? 'donation-collection-content-safari' : 'donation-collection-content-not-safari'"
      >
        <div
          v-for="(item,j) in collectionInfo.skuVos[selectCollectionIndex].uniqueVos"
          @click="selectCollection(item)"
          :key="j"
          class="donation-collection-content-item row-between"
        >
          <div style="margin-left:21px;">
            <p class="donation-select-collection-item-title">{{ collectionInfo.skuVos[selectCollectionIndex].name }}</p>
            <p class="donation-select-collection-item-id">ID：{{ item.showId  && item.showId != '0'? item.showId :item.uniqueCode }}</p>
          </div>
          <img
            style="margin-right:33px;"
            class="donation-select-collection-item-selectimg"
            v-if="selectUniqueList.filter(i => i == item.uniqueCode).length"
            src="../assets/icon/select-collection.png"
          />
          <img
            style="margin-right:33px;"
            class="donation-select-collection-item-selectimg"
            v-else
            src="../assets/icon/no-select-collection.png"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!isSearch && collectionSelectList.length"
      class="donation-collection-bottom row-between"
    >
      <p class="donation-collection-bottom-num">已选 {{ selectUniqueList.length }} 个</p>
      <button
        @click="confirmSelect()"
        class="donation-collection-bottom-button"
      >确认选择</button>
    </div>
    <div
      v-if="!collectionSelectList.length"
      class="col-center"
      style="width:100%;height: calc(100vh - 7rem);"
    >
      <img
        style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
        src="../assets/icon/no-list.png"
      />
      <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
    </div>
  </div>
</template>
<script>
import api from '../api/index-client'
import { mapGetters } from "vuex";
import config from '../config'
export default {
  data () {
    return {
      mediaType: config.mediaType,
      selectIdList: [],
      selectIdListNotConfirm: [],
      selectUniqueList: [],
      selectUniqueListNotConfirm: [],
      collectionList: [],
      collectionList1: [],//藏品全部数据
      collectionList2: [],//盲盒全部数据
      collectionInfo: {},
      collectionInfo1: {},//藏品数据
      collectionInfo2: {},//盲盒数据
      collectionSelectList: [],
      selectCollectionIndex: 0,
    }
  },
  watch: {
    // 搜索值变化是筛选collectionList中符合条件的值
    searchCollectionName () {
      this.collectionSelectList = []
      if (this.searchCollectionName) {
        this.collectionSelectList = this.collectionList.filter(i => i.name.indexOf(this.searchCollectionName) !== -1)
      } else {
        this.collectionSelectList = this.collectionList
      }
    },
    // 确认选择
    confirmDonationSelect () {
      if (this.confirmDonationSelect) {
        this.selectUniqueList = this.selectUniqueListNotConfirm
        this.selectIdList = this.selectIdListNotConfirm
        this.calculateSelectNum()
        localStorage.setItem('selectUniqueList',this.selectUniqueList.join(','))
        localStorage.setItem('selectIdList',this.selectIdList.join(','))
      }
    },
    isSearch () {
      if (this.isSearch) {
        this.selectUniqueListNotConfirm = []
        this.selectIdListNotConfirm = []
        this.collectionSelectList = this.collectionList
        this.selectUniqueList.map(i => {
          this.selectUniqueListNotConfirm.push(i)
        })
        this.selectIdList.map(i => {
          this.selectIdListNotConfirm.push(i)
        })
      } else {
        if (this.selectCollectionTab == '1') {
          // 藏品转赠tab切换
          this.giftChooseList(0)
        } else {
          // 盲盒转赠tab切换
          this.giftChooseList(1)
        }
      }
    },
    // tab切换
    selectCollectionTab () {
      // 清除选中数据
      this.selectUniqueList = []
      this.selectIdList = []
      localStorage.setItem('selectUniqueList',this.selectUniqueList.join(','))
      localStorage.setItem('selectIdList',this.selectIdList.join(','))
      if (this.selectCollectionTab == '1') {
        // 藏品转赠tab切换
        this.giftChooseList(0)
      } else {
        // 盲盒转赠tab切换
        this.giftChooseList(1)
      }
    }
  },
  computed: {
    ...mapGetters(["searchCollectionName","isSearch","confirmDonationSelect","selectCollectionTab"])
  },
  mounted () {
    if (localStorage.getItem('selectUniqueList')) {
      this.selectUniqueList = localStorage.getItem('selectUniqueList').split(',')
    }
    if (localStorage.getItem('selectIdList')) {
      this.selectIdList = localStorage.getItem('selectIdList').split(',')
    }

    if (localStorage.getItem('selectCollectionTab') && localStorage.getItem('selectCollectionTab') == 0) {
      this.giftChooseList(0)
    } else if (localStorage.getItem('selectCollectionTab') && localStorage.getItem('selectCollectionTab') == 1) {
      this.giftChooseList(1)
    } else {
      this.giftChooseList(0)
    }

  },
  methods: {
    // 是否为Safari浏览器
    isSafari () {
      return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
    },
    // 计算每个藏品集合中选中了几个
    calculateSelectNum () {
      this.collectionInfo.skuVos.map(i => {
        i.selectSize = 0
        this.selectUniqueList.map(item1 => {
          i.uniqueVos.map(item2 => {
            if (item1 == item2.uniqueCode) {
              i.selectSize += 1
            }
          })
        })
      })
    },
    // 获取藏品列表
    giftChooseList (m) {
      if (m == 0 && this.collectionList1.length) {
        localStorage.setItem('selectCollectionTab',m)
        this.$store.commit('SET_SELECTCOLLECTIONTAB','1')
        this.collectionList = this.collectionList1
        this.collectionInfo = this.collectionInfo1
        if (this.searchCollectionName && this.isSearch) {
          this.collectionSelectList = this.collectionList.filter(i => i.name.indexOf(this.searchCollectionName) !== -1)
        } else {
          this.collectionSelectList = this.collectionList
        }
        this.calculateSelectNum()
      } else if (m == 1 && this.collectionList2.length) {
        localStorage.setItem('selectCollectionTab',m)
        this.$store.commit('SET_SELECTCOLLECTIONTAB','2')
        this.collectionList = this.collectionList2
        this.collectionInfo = this.collectionInfo2
        if (this.searchCollectionName && this.isSearch) {
          this.collectionSelectList = this.collectionList.filter(i => i.name.indexOf(this.searchCollectionName) !== -1)
        } else {
          this.collectionSelectList = this.collectionList
        }
        this.calculateSelectNum()
      } else {
        api
          .post('gift/choose/list?skuType=' + m)
          .then(result => {
            if (result.data.success) {
              localStorage.setItem('selectCollectionTab',m)
              this.$store.commit('SET_SELECTCOLLECTIONTAB',m == 0 ? '1' : '2')
              this.collectionList = []
              this.collectionInfo = result.data.data
              this.calculateSelectNum()
              result.data.data.skuVos.map(i => {
                i.uniqueVos.map(j => {
                  this.collectionList.push({
                    image: i.image,
                    name: i.name,
                    skuNo: i.skuNo,
                    showId: i.showId,
                    uniqueCode: j.uniqueCode,
                    id: j.id,
                    amount: j.amount,
                  })
                })
              })
              if (m == 0) {
                this.collectionList1 = this.collectionList
                this.collectionInfo1 = this.collectionInfo
              } else {
                this.collectionList2 = this.collectionList
                this.collectionInfo2 = this.collectionInfo
              }
              if (this.searchCollectionName && this.isSearch) {
                this.collectionSelectList = this.collectionList.filter(i => i.name.indexOf(this.searchCollectionName) !== -1)
              } else {
                this.collectionSelectList = this.collectionList
              }
            } else {
              this.$store.commit('SET_SELECTCOLLECTIONTAB',m == 0 ? '2' : '1')
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
            this.$store.commit('HIDE_APPLOADING')
          })
      }
    },
    // 切换藏品
    selectDonationCollectionIndex (index) {
      // 当前所选中藏品的下标
      this.selectCollectionIndex = index
    },
    // 确认选择
    confirmSelect () {
      if (this.collectionInfo.transferNumLimit != null && this.collectionInfo.transferNumLimit !== "" && this.selectUniqueList.length > this.collectionInfo.transferNumLimit) {
        this.$toast({
          message: "单笔最大转赠上限" + this.collectionInfo.transferNumLimit + "个",
          icon: require('../assets/icon/toast-error.png'),
        });
      } else if (this.collectionInfo.transferDayLimit != null && this.collectionInfo.transferDayLimit !== "" && this.selectUniqueList.length > this.collectionInfo.transferDayLimit) {
        this.$toast({
          message: "今日可转赠数量剩余" + this.collectionInfo.transferDayLimit + "个",
          icon: require('../assets/icon/toast-error.png'),
        });
      } else {
        this.$router.replace('/donation')
      }

    },
    // 搜索选择藏品
    selectDonationCollection (item) {
      if (this.selectUniqueListNotConfirm.filter(i => i == item.uniqueCode).length) {
        this.selectUniqueListNotConfirm.map((i,j) => {
          if (i == item.uniqueCode) {
            this.selectUniqueListNotConfirm.splice(j,1)
            this.selectIdListNotConfirm.splice(j,1)
          }
        })
      } else {
        this.selectUniqueListNotConfirm.push(item.uniqueCode)
        this.selectIdListNotConfirm.push(item.id)
      }
      this.calculateSelectNum()
    },
    // 选择藏品
    selectCollection (item) {
      if (this.selectUniqueList.filter(i => i == item.uniqueCode).length) {
        this.selectUniqueList.map((i,j) => {
          if (i == item.uniqueCode) {
            this.selectUniqueList.splice(j,1)
            this.selectIdList.splice(j,1)
          }
        })
      } else {
        this.selectUniqueList.push(item.uniqueCode)
        this.selectIdList.push(item.id)
      }
      this.calculateSelectNum()
      localStorage.setItem('selectUniqueList',this.selectUniqueList.join(','))
      localStorage.setItem('selectIdList',this.selectIdList.join(','))
    },
  },
}
</script>
<style lang="less" scoped>
.donation-select-collection-container {
  margin-top: 4.625rem;
  height: calc(100vh - 4.625rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .donation-select-collection-list {
    width: 94vw;
    margin-left: 3vw;
    height: auto;
    background: #2c2c2c;
    border-radius: 8px;
    .donation-select-collection-item {
      height: 116px;
      width: 94%;
      margin-left: 3%;
      border-bottom: 1px solid #3f3f3f;
      .donation-select-collection-item-img {
        width: 5rem;
        height: 5rem;
        margin-right: 1rem;
        border-radius: 8px;
      }
    }
  }
  .donation-select-collection-item-title {
    font-size: 18px;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #ffffff;
    line-height: 25px;
    width: 100%;
    word-break: break-all;
  }
  .donation-select-collection-item-id {
    font-size: 12px;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
    margin: 8px 0;
    word-break: break-all;
    width: 100%;
  }
  .select-collection-type {
    width: 14px;
    height: auto;
  }
  .donation-select-collection-item-selectimg {
    width: 20px;
    height: 20px;
  }
  .donation-collection-tip-safari {
    height: calc(100vh - 134px);
  }
  .donation-collection-tip-not-safari {
    height: calc(100vh - 134px);
  }
  .donation-collection-tip {
    width: 100px;
    overflow-y: auto;
    -webkit-overflow-scroll: touch;
    .donation-collection-tip-item {
      width: 100px;
      height: 100px;
    }
  }
  .donation-collection-tip::-webkit-scrollbar {
    display: none;
  }
  .donation-collection-content-safari {
    height: calc(100vh - 134px);
  }
  .donation-collection-content-not-safari {
    height: calc(100vh - 134px);
  }
  .donation-collection-content {
    width: calc(100vw - 100px);
    overflow-y: auto;
    background: #2c2c2c;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: unset;
    -webkit-overflow-scroll: touch;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    .donation-collection-content-item {
      width: calc(100vw - 100px);
      min-height: 76px;
      border-bottom: 1px solid #3f3f3f;
    }
  }
  .donation-collection-content::-webkit-scrollbar {
    display: none;
  }
  .donation-collection-num-image {
    width: 30px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: -1px;
  }
  .donation-collection-num {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: -1px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
  }
  .donation-collection-bottom {
    width: 100vw;
    height: 60px;
    position: fixed;
    bottom: 0;
    background: #080808;
    z-index: 2000;
    -webkit-overflow-scroll: touch;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    .donation-collection-bottom-num {
      margin-left: 20px;
      font-size: 16px;
      font-family: lantingheiweight;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
    }
    .donation-collection-bottom-button {
      width: 210px;
      height: 46px;
      margin-right: 13px;
      background-image: url('../assets/icon/confirm-button.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-color: transparent;
      font-size: 1rem;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.375rem;
      letter-spacing: 2px;
      cursor: pointer;
      background-size: cover;
    }
  }
}
</style>